import {Component, Inject, Prop, Vue, Watch} from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfBrand from "@/vue/domain/brand/df-brand";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfContent from "@/vue/domain/content/df-content";
import vuescroll from "vuescroll";
import DfUser from "@/vue/domain/user/df-user";

@Component({
  components: {
    DfPrivateArea: () => Utils.externalComponent2("df-private-area"),
    DfModal: () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue"),
    vuescroll,
  },
})
export default class DfHeaderMobileComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: null })
  customHeaderBackgroundImageUrl!: string;
  @Prop({ type: Boolean, default: true })
  showBrandLogoImageUrl!: boolean;
  @Prop({ type: Boolean, default: true })
  showBackButton!: boolean;
  @Prop({ type: Boolean, default: false })
  setBackgroundImageSizeContain!: boolean;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get linkButtons(): Array<DfContent> {
    return this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "BTN");
  }

  get headerPreviewStyle(): string {
    const style: any = {};
    const headerPreviewStyle: string = this.customHeaderBackgroundImageUrl ? this.customHeaderBackgroundImageUrl : Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_HEADER_MOBILE, `${Utils.getPublicPath()}/assets/header_mobile.png`);

    if (headerPreviewStyle) {
      style.backgroundImage = `url('${headerPreviewStyle}')`;
    }

    if (this.setBackgroundImageSizeContain) {
      style.backgroundSize = `contain`;
    }

    if (this.extraLogo) {
      style.justifyContent = "flex-start";
    }

    return style;
  }

  get brandLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get extraLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_EXTRA_LOGO, null);
  }

  get brandLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO_HEADER, null);
  }

  get brandLogoImageUrl(): string {
    return this.brandLogoHeader ? this.brandLogoHeader : this.brandLogo;
  }

  get backIconImageUrl(): string {
    return `${Utils.getPublicPath()}/assets/back-icon.svg`;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get promotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get linkButtonMainSite(): Array<DfContent> {
    return this.linkButtons.filter((linkButton: DfContent) => {
      const isMainSite: boolean = Utils.getPropertyValue(linkButton, Utils.PROPERTY_MAIN_SITE, "BOOLEAN");
      return isMainSite;
    });
  }

  get mainSiteURL(): string {
    if (this.linkButtonMainSite.length > 0) {
      const mainSiteButton = this.linkButtonMainSite[0];
      const mainSiteURL: string = Utils.getPropertyValue(mainSiteButton, Utils.PROPERTY_URL, "STRING");
      return mainSiteURL;
    }
    return null;
  }

  get enablePrivateArea(): boolean {
    const rule01: boolean = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false);
    const rule02: boolean = Utils.enablePrivateArea();
    return !rule01 && rule02;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  get userInitials(): string {
    const firtname: string = Utils.getPropertyValue(this.user, "FIRST_NAME", "STRING");
    const lastname: string = Utils.getPropertyValue(this.user, "LAST_NAME", "STRING");

    return firtname && lastname ? `${firtname[0].toUpperCase()}${lastname[0].toUpperCase()}` : null;
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  @Watch("showLogin")
  private showLoginChange() {
    if (this.showLogin && (<any>this.$el).checkVisibility()) {
      this.$store.dispatch("setShowLogin", false);
      this.openPrivateAreaMobileModal();
    }
  }

  private goBack() {
    Utils.goBack(this);
  }

  private openPrivateAreaMobileModal() {
    this.$root.$emit("modules-modal.open", "df-modal.id.private-area-mobile");
  }

  private closePrivateAreaMobileModal() {
    this.$root.$emit("modules-modal.close", "df-modal.id.private-area-mobile");
  }

  get brandLogoUrl(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_LOGO_URL, "STRING");
  }
}
